.MuiOutlinedInput-input{
    padding: 12px 14px!important;
}
.MuiInputBase-input{
    font-size: 14px!important;
}
.MuiAutocomplete-inputRoot{
    padding: 2px!important;
}
.MuiChip-root{
    height: 27px!important;
}
.form-control{
    /* display: inline-block!important; */
    margin-bottom: 10px!important;
}
::-webkit-file-upload-button {
    background: #6ed1ff;
    color: white;
    padding: 8px!important;
    border: none;
    border-radius: 8px;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #cdcccc; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  ::-webkit-scrollbar {
    height: 4px;              /* height of horizontal scrollbar ← You're missing this */
    width: 4px;               /* width of vertical scrollbar */
    border: 1px solid #d5d5d5;
    /* display: none; */
  }


  .mapRow{
    text-align: center;
    height: 100%;
    width: 100%;
  }
  
  .map-container{
    height: 25vh;
    width: 100%;
  }